.tab-module {
	display: flex;
  justify-content: center;
  align-items: center;
	ul {
		padding-left: 0px; 
		margin-bottom: 0;
		list-style: none;
		li {
			text-align: center;
			color: $heading-color;
			font-size: 16px;
			font-weight: 600;
			letter-spacing: 3px;
			text-transform: uppercase;
			padding: 12px 20px 18px 20px;
			display: inline-block;
			background: transparent;
			border-radius: 4px 4px 0 0;
			margin-right: 6px;
			outline: none;
			cursor: pointer;
			margin-top: 10px;
			border-top: 1px solid tint($placeholder-color, 60);
			border-left: 1px solid tint($placeholder-color, 60);
			border-right: 1px solid tint($placeholder-color, 60);
			&.active {
				background: $white-color;
				position: relative;
				&:before {
					content: '';
					height: 1px;
					width: 100%;
					background: $white-color;
					position: absolute;
					bottom: -1px;
    			left: 0;
				}
			}
		}
	}
	
}