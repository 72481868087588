.counters {
    background: #0f479a;
    color: #fff;
    padding: 40px 20px;
    border-top: 3px lightskyblue solid;
}

.counters .container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 30px;
    text-align: center;
}

.counters i {
    color: lightskyblue;
    margin-bottom: 5px;
}

.counters .counter {
    font-size: 45px;
    margin: 10px 0;
}
.carousel {
    position: absolute !important;
    margin-top: 10px;
    opacity: 0.7;
    width: 100% !important;
    text-align: center !important;
}

.fill-remaining-space {
    flex: auto;
}

.fadeMe {
    opacity: 0.5;
    background: #000;
    width: 100%;
    height: 100%;
    z-index: 10;
    top: 0;
    left: 0;
    position: fixed;

    p{
        top: 40%;
        position: absolute;
        left: 40%;
        color: #fff;
        font-size: 30px;
    }
}


@media (max-width: 700px) {
    .counters .container {
        grid-template-columns: repeat(2, 1fr);
    }

    .counters .container > div:nth-of-type(1),
    .counters .container > div:nth-of-type(2) {
        border-bottom: 1px lightskyblue solid;
        padding-bottom: 20px;
    }
}
