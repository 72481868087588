
// TODO
// Using the button-variant mixins. Since you only want to change the primary button color, and not the entire primary theme color, you need to use the button-variant mixins in SASS.

// Example
// $mynewcolor:teal;

// .btn-primary {
//     @include button-variant($mynewcolor, darken($mynewcolor, 7.5%), darken($mynewcolor, 10%), lighten($mynewcolor,5%), lighten($mynewcolor, 10%), darken($mynewcolor,30%));
// }

// .btn-outline-primary {
//     @include button-outline-variant($mynewcolor, #222222, lighten($mynewcolor,5%), $mynewcolor);
// }

.btn {
	font-size: 14px;
}

.btn-icon {
	display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
  height: 35px;
  width: 35px;
  color: $label-color;
  &:hover, &.active {
  	color: $primary-color;
  }
  &:focus {
  	box-shadow: none;
  }
}

.btn-link-with-icon {
  color: $label-color;
  i {
    color: $label-color;
    font-size: 12px;
    margin-right: 6px;
  }
  &:hover, &:focus {
    text-decoration: none;
    color: $primary-color;
    box-shadow: none;
    i {
      color: $primary-color;
    }
  }
}

.btn-primary {
	box-shadow: none !important;
	@include button-variant($primary-color, $primary-color, shade($primary-color, 20), shade($primary-color, 20), tint($primary-color, 10), tint($primary-color, 10));
}

.btn-success {
  box-shadow: none !important;
}

.btn-secondary {
	box-shadow: none !important;
	color: $label-color !important;
	@include button-variant($white-color, tint($placeholder-color, 60), shade($white-color, 2), tint($placeholder-color, 70), tint($white-color, 10), tint($placeholder-color, 70));
}

.btn-search {
  width: 100%;
}