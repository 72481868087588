.form-group {
	label {
		font-size: 14px;
		color: $label-color;
	}
	.form-control {
		font-size: 14px;
  }
  .error-msg {
    font-size: 12px;
    color: $invalid-color;
    margin-top: 2px;
  }
}

.form-content {
  display: flex;
  label {
    font-size: 14px;
    color: $label-color;
    flex: 1;
  }
  .value {
    flex: 1;
    font-size: 14px;
    font-weight: 500;
  }
}

.form-control {
	border: 1px solid tint($placeholder-color, 60);
	&:focus {
		border: 1px solid tint($placeholder-color, 30) !important;
	}
	&.is-invalid {
		&:focus {
			border-color: $invalid-color !important;
      box-shadow: none;
      background: unset;
    }
    background: unset;
	}
	&.is-valid {
		&:focus {
			border-color: $valid-color !important;
			box-shadow: none;
		}
  }
  &.ng-touched.ng-invalid, &.ng-touched.ng-invalid:focus {
    border-color: $invalid-color !important;
  }
}
.form-check, .custom-checkbox {
	label {
		font-size: 14px;
		color: $label-color;
	}
}

.invalid-message {
	p {
		border: 1px solid $invalid-color;
		color: $invalid-color;
		border-radius: .25rem;
		padding: 2px 6px;
		text-align: center;
		font-size: 14px;
	}
}

.valid-message {
	p {
		border: 1px solid $valid-color;
		color: $valid-color;
		border-radius: .25rem;
		padding: 2px 6px;
		text-align: center;
		font-size: 14px;
	}
}