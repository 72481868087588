.test {
  td {
    // background-color: $red-color;
    // color: $white-color !important;
  }
}

.ows-actions {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 0.5rem;
  .filter-actions  {
    margin-bottom: 0px;
    .search-filter  {
      margin-right: 0px;
      height: 100%;
    }
    .search-wrapper {
      display: flex;
      align-items: flex-end;
      .search-icon {
        bottom: 10px;
      }
      input {
        border: none;
        border-bottom: 1px solid $placeholder-color;
        font-size: 12px;
        // padding-left: 10px;
        width: 220px;
        padding-right: 6px;
      }
    }
  }
}

.alert-indicator-wrapper {
  height: 10px;
  border: 1px solid $background-color;
  border-radius: 2px;
  width: 45px;
  box-shadow: 0px 1px 2px tint($background-color, 20);
  .alert-indicator {
    height: 100%;
    background-color: $red-color;
    // width: 0%;
    @extend .transition; 
    &:before {
      content: attr(data-percentage);
      font-size: 8px;
      display: block;      
      padding-top: 8px;
    }
  }
}

.loader-container {
  display: flex;
  height: 50vh;
  position: relative;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  color: tint(#2b94d5, 20);
}

.ows-table-container {
  padding-bottom: 8px;
  overflow: auto;
  height: calc(100vh - 260px);
  max-height: calc(100vh - 260px);
  .table {
    height: 100%;
  }
}

