.mapping-tool-layout {
	background: #fff;
	height: calc(100vh - 144px);
	padding: 30px;
	overflow: auto;
	border: 1px solid tint($placeholder-color, 60);
}
.mapping-content-body {
	width: 1200px;
	margin: 0 auto;
	.row {
		margin-bottom: 1.2rem;
	}
	.i-b {
		border-color: $input-blue-border;
		background-color: $input-blue;
		font-size: .875rem;
	}
	.i-g {
		border-color: $input-green-border;
		background-color: $input-green;
		&:focus {
			border: 1px solid shade($input-green-border, 10) !important;
		}
		&.is-invalid {
			border-color: $red-color;
			background-color: tint($red-color, 90);
			@include placeholder {
				color: tint($red-color, 60);
			}
			&:focus {
				border-color: $red-color !important;
				// border-color: $input-green-border !important;
				box-shadow: none;
			}
		}
		&.is-valid {
			&:focus {
				border-color: $input-green-border !important;
				box-shadow: none;
			}
		}
		@include placeholder {
			color: shade($input-green-border, 10);
		}
	}
}

.original-arrow {
	margin: 30px 0;
	p {
		text-align: center;
		position: relative;
		color: $heading-color;
		&.first {
			&:before {
				position: absolute;
				top: 50%;
				right: -8%;
				content: '';
				height: 2px;
				width: 50%;
				background: $placeholder-color;
			}
		}
		&.second {
			&:after {
				content: '\f054';
				position: absolute;
				top: 1px;
    		left: 232px;
				color: red;
				font-family: "Font Awesome 5 Free";
    		font-weight: 900;
    		color: $placeholder-color;
			}
			&:before {
				position: absolute;
				top: 50%;
				left: -8%;
				content: '';
				height: 2px;
				width: 50%;
				background: $placeholder-color;
			}
		}
	}
}

.go-group {
  position: relative;
  input {
    padding-right: 100px;
  }
  .btn {
  	.rotating {
    	margin-right: 6px;
  	}
    position: absolute;
    right: 4px;
    top: 4px;
    padding: 2px 20px;
  }
}
p.form-control {
	margin-bottom: 0;
}