.upload-manager-layout {
	padding-bottom: 15px;
	.processing {
		i {
			margin-right: 6px;
		}
	}
	.done {
		i {
			color: $green-color;
			margin-right: 6px;
		}
	}
	.invalid {
		color: $red-color;
	}
	.action {
		a {
			cursor: pointer;
			color: $red-color;
			&:hover {
				color: tint($red-color, 30);
			}
		}
	}

}