.notification-container {
    font-size: 14px;
    box-sizing: border-box;
    position: fixed;
    z-index: 999999;
    button {
        // position: relative;
        margin-left: 20px;
        right: -0.3em;
        top: -0.3em;
        float: right;
        font-weight: 700;
        color: #fff;
        outline: none;
        border: none;
        text-shadow: 0 1px 0 #fff;
        opacity: 0.8;
        line-height: 1;
        font-size: 16px;
        padding: 0;
        cursor: pointer;
        background: 0 0;
        border: 0;
    }
    .request-access {
        background: #e7e7e7;
        padding: 10px;
        color: #000;
        opacity: 1;
        right: 0;
        top: 0;
        text-shadow: none;
        font-weight: bold;
        font-size: 14px;
        margin-top: 20px;
    }
}
.top-right {
    top: 12px;
    right: 12px;
    transition: transform 0.6s ease-in-out;
    animation: toast-in-right 0.7s;
}
.bottom-right {
    bottom: 12px;
    right: 12px;
    transition: transform 0.6s ease-in-out;
    animation: toast-in-right 0.7s;
}
.top-left {
    top: 12px;
    left: 12px;
    transition: transform 0.6s ease-in;
    animation: toast-in-left 0.7s;
}
.bottom-left {
    bottom: 12px;
    left: 12px;
    transition: transform 0.6s ease-in;
    animation: toast-in-left 0.7s;
}
.notification {
    background: #fff;
    transition: 0.3s ease;
    position: relative;
    pointer-events: auto;
    overflow: hidden;
    margin: 0 0 6px;
    padding: 30px;
    margin-bottom: 15px;
    width: 300px;
    max-height: 100px;
    border-radius: 3px 3px 3px 3px;
    box-shadow: 0 0 10px #999;
    color: #000;
    opacity: 0.9;
    background-position: 15px;
    background-repeat: no-repeat;
    &:hover {
        box-shadow: 0 0 12px #fff;
        opacity: 1;
        cursor: pointer;
    }
}
.notification-title {
    font-weight: 700;
    font-size: 14px;
    text-align: left;
    margin-top: 0;
    margin-bottom: 6px;
    // width: 300px;
    height: 18px;
    color: #fff !important;
}
.notification-message {
    margin: 0;
    text-align: left;
    height: 18px;
    margin-left: -1px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.notification-image {
    float: left;
    margin-right: 15px;
    img {
        width: 30px;
        height: 30px;
    }
}
.toast {
    // height: 70px;
    // width: 365px;
    color: #fff;
    padding: 15px 15px 20px 15px;
}

.request-access-form {
    min-height: 180px !important;
}
@keyframes toast-in-right {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes toast-in-left {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(0);
    }
}
