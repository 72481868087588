.skeleton-loader-container {
  margin: 1rem auto;
  ul {
    list-style-type: none;
    padding-left: 0px;
    li {
      display: flex;
      align-items: flex-start;
      margin-bottom: 0.5rem;
      padding: 10px;
      .circle {
        border-radius: 50%;
        margin-right: 1rem;
        width: 34px;
        height: 34px;
        overflow: hidden;
      }
      .loader {
        position: relative;
        box-shadow: 0px 1px 1px 0px rgb(242, 243, 248);
        &:before {
          content: '';
          position: absolute;
          background: 0 0/100px 100% no-repeat $background-color;
          background-image:linear-gradient(90deg,rgba(255,255,255,0),rgba(255,255,255,0.8),rgba(255,255,255,0));
          @include animation(progress, 1.2s, infinite);
          width: 100%;
          height: 100%;
        }
      }
      .info-wrapper
      {
        flex: 1;
        div {
          border-radius: 2px;
          overflow: hidden;
        }
        div:not(:last-child) {
          margin-bottom: 10px;
        }
      }
      .large {
        width: 80%;
        height: 0.5rem;
      }
      .medium {
        width: 50%;
        height: 0.3rem;
      }
    }
  }
}