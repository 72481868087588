.vertical-center {
  position: absolute;
  top: 50%;
  @include transform(translateY(-50%));
}

.horizontal-center {
  position: absolute;
 left: 50%;
 @include transform(translateX(-50%));
}

.position-center {
  position: absolute;
  top: 50%;
  left: 50%;
  @include transform(translate(-50%,-50%));
}