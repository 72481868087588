.filter-fields {

  input,
  select {
    font-size: 12px;
    height: auto;
    padding: 4px;
    border: 1px solid tint($placeholder-color, 60);

    &:focus {
      border: 1px solid tint($placeholder-color, 30);
    }
  }

  &.dropdown {
    .btn {
      font-size: 12px;
      border-radius: .25rem;
      border: 1px solid tint($placeholder-color, 60);
    }
  }
}

// Fixed header
// .pricing-table-container {
//   height: calc(100vh - 142px);
//   overflow: scroll;
//   &.fixed-height {
//     height: calc(100vh - 190px);
//   }
// }

.fetching-data {
  height: calc(100vh - 142px);
  display: flex;
  justify-content: center;
  align-items: center;
  background: $white-color;
  border: 1px solid tint($placeholder-color, 60);
}

.allign-loader {
  i {
    font-size: 40px;
    color: tint($label-color, 50);
  }
}

.floatThead-container {
  z-index: 9 !important;
}

.weight-500{
  font-weight: 500;
}

.disabled-content{

    pointer-events: none;
    opacity: 0.4;

}