ng-modal {
  /* modals are hidden by default */
  display: none;

  .ng-modal {
    /* modal container fixed across whole screen */
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    /* z-index must be higher than .modal-background */
    z-index: 1000;

    /* enables scrolling for tall modals */
    overflow: auto;
  }

  .ng-modal-background {
    /* modal background fixed across whole screen */
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    /* semi-transparent black  */
    background-color: #000;
    opacity: 0.75;

    /* z-index must be below .modal and above everything else  */
    z-index: 900;
  }
}

body.ng-modal-open {
  /* body overflow is hidden to hide main scrollbar when modal window is open */
  overflow: hidden;
}

.ng-modal-body {
  padding: 20px;
  background: #fff;
  max-width: 600px;
  border-radius: 4px;
  /* margin exposes part of the modal background */
  margin: 0 auto;
  top: 50%;
  position: relative;
  transform: translate(0, -50%);


  .ng-modal-title {
    @include clearfix;
    h1 {
      float: left;
      margin-bottom: 0;
      font-size: 16px;
      color: $heading-color;
    }
    i {
      float: right;
      cursor: pointer;
      &:hover {
        transition: all 0.3s linear;
        opacity: 0.8;
      }
    }
  }
  .ng-content-body {
    margin-top: 30px;
    margin-bottom: 10px;
    .ng-content-results {
      border-top: 1px solid tint($placeholder-color, 70);
      padding-top: 20px;
      margin-top: 20px;
      .form-group {
        margin-bottom: 20px;
        label {
          // color: $heading-color;
          // font-size: 14px;
          // font-weight: 500;
        }
        .form-control {
          font-weight: 500;
        }
      }
    }
    .clear {
      cursor: pointer;
      color: tint($red-color, 30);
      font-size: 12px;
      i { font-size: 12px; }
      &:hover {
        transition: all 0.3s linear;
        color: tint($red-color, 50);
      }
    }
    .go-group {
      position: relative;
      input {
        padding-right: 100px;
      }
      .btn {
        position: absolute;
        right: 4px;
        top: 4px;
        padding: 2px 20px;
      }
    }
  }
}