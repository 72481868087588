.l-grid-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    // width: 660px;
    // justify-content: center;
    align-content: center;
    // height: 100vh;
    // margin: 0 auto;
}

.l-logo {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    align-items: flex-start;
    padding: 20px 10px;
    a {
        width: 160px;

        img {
            // width: 100%;
            max-height: 30px;
        }
    }
}

.no-white-space {
    white-space: nowrap;
}

.no-resize {
    resize: none;
}

.vertical-middle {
    vertical-align: middle !important;
}

.cursor-pointer {
    cursor: pointer;
}

.pagination-disabled {
    pointer-events: none;
    opacity: 0.7;
    cursor: not-allowed;
}
@media (max-width: 1199px) {
    .l-grid-container {
        justify-content: center;
    }
}
@media (min-width: 1200px) {
    .l-grid-container {
        justify-content: flex-start;
    }
}
.h-88 {
    height: 88vh !important;
}
.bg {
    height: 100%;
    background: #f2f3f8;
}
