.feedback-wrapper {
    position: absolute;
    // top:0;
    right: 0;
    margin-top: 35vh;
    margin-right: -28px;
    transform: rotate(-90deg);
    z-index: 999;

    .feedback-button {
        background-color: #636b9f;
        font-size: 10px;
        span {
            color: #fff;
            font-size: 10px;
        }
    }
    .feedback-button:focus {
        outline: none;
        box-shadow: none;
    }
}

.feedback-dashboard-wrapper {
    position: absolute;
    bottom: 10px;
    right: 10px;
    // margin: 20px 10px 20px 0;
    // margin-right: 30px;
    // margin-bottom: 20px;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
    z-index: 5;

    .feedback-dashboard-button {
        background-color: #636b9f;
        padding: 10px 15px;
        span {
            color: #fff;
            font-size: 12px;
        }
        i {
            color: #fff;
            margin-right: 10px;
        }
    }
    .feedback-dashboard-button:focus {
        outline: none;
        box-shadow: none;
    }
}

.feedback-loader {
    position: absolute;
    bottom: 0;
    right: 0;
    margin-right: 30px;
    margin-bottom: 20px;
}

.feedback-modal-wrapper {
    // height: 400px;
    width: 300px;
    border: none;
    .close {
        font-size: 20px;
        color: #fff;
        font-weight: 400;
    }
    .feedback-title {
        font-size: 14px;
        font-weight: 400;
        color: #fff;
        display: block;
        margin: 0px;
    }
    .feedback-icons-wrapper {
        display: flex;
        background-color: #0097dd;
        padding: 10px 20px;
    }
    .feedback-close {
        color: #fff;
        line-height: unset;
    }
    .feedback-quote {
        opacity: 0.05;
        font-size: 70px;
        text-align: center;
    }
    .feedback-desc {
        display: block;
        font-size: 10px;
        color: #868db6;
        text-align: center;
        margin-top: -30px;
    }
    .feedback-icon-q {
        text-align: center;
        padding-top: 20px;
    }
    .feedback-form {
        margin: 50px 20px -10px 20px;
        padding: 0;
        min-height: 225px;
        height: auto;

        .dropdown-menu {
            width: 100%;
        }
        .dropdown-toggle::after {
            float: right;
        }
    }
    .feedback-modal {
        width: 100%;
    }
    textarea {
        height: 80px;
        resize: none;
    }
    .feedback-modal {
        display: inline;
    }
    .feedback-button {
        // padding:0px 30px;
        font-weight: 400;
        cursor: pointer;
        width: 88%;
        color: #fff;
        background-color: #75c018;
        border: none;
    }
    .modal-body {
        padding: 0 !important;
    }
}
.error-message {
    color: #dc3545;
    text-align: center;
}
.custom-dialog-dropdown {
    button {
        width: 100%;
        text-align: left;
    }
    .active-item {
        color: #0996d7 !important;
        background: rgba(0, 0, 0, 0.12) !important;
    }
}

.custom-dialog-container {
    p {
        float: right;
        font-size: 10px;
        color: #868db6;
    }
}
