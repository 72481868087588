$font-family: "ArtifaktLegendOfc-ExtraBold";

.fa-exclamation {
    font-size: 0.8rem;
    color: #dc3545;
}

.navbar-brand {
    display: flex;
    padding: 10px 0;
    align-items: center;
    justify-content: center;

    .brand-name {
        font-family: $font-family;
        color: #000;
        text-transform: uppercase;
        margin: 0;
        font-weight: bold;
    }
}
