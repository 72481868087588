// Base
$background-color: #f2f3f8;

// Theme
$primary-color: #0996d7;
$white-color: #ffffff;
$blue: #0996d7;
$dark-blue: #03256c;
$secondary-color: #0796d6;

// Form & Typography
$label-color: #646c9a;
$default-text-color: #495057;
$placeholder-color: #a1a8c3;
$invalid-color: #dc3545;
$valid-color: #28a745;
$heading-color: #434349;

// Action
$red-color: #dc3545;
$green-color: #28a745;
$black-color: #000;

// Input
$input-blue: #dae8fc;
$input-blue-border: #b6cae7;

$input-green: #d5e8d4;
$input-green-border: #bad7b0;

$light-black: #818386;
