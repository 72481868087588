@import url('https://fonts.googleapis.com/css?family=Squada+One&display=swap');

@font-face{
    font-family: "ArtifaktElement-Regular";
    src: url("/src/static/assets/fonts/ArtifaktElement/ArtifaktElement-Regular.woff") format('woff'),
    url("/src/static/assets/fonts/ArtifaktElement/ArtifaktElement-Regular.woff2") format('woff2');
}

.filter-container{
    height: 90vh;
}
.card{
    border: none;
    box-shadow: 0px 0px 10px 0px #00000014;
    // height: 100%;
}

