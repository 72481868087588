.report-temp-container{
  margin: 1rem !important;
  font-size: 12px;
  font-weight: 400;
  color: black;
  padding: 20px;
  background-color: $white-color;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  border: 1px solid tint($placeholder-color, 60);
}

.report-temp-container .header{
  padding: 10px;
  text-align: center;
      text-transform: uppercase;
      border-bottom: 1px solid #365F91;
      font-size: 18px;
}

  .report-temp-container .para-cont{
      padding: 10px;
  }

  .report-temp-container div{
      padding: 5px;
  }

  .report-temp-container .sub-header{
      font-size: 16px;
      font-weight: 600;
      text-align: left;
      color: #365F91; 
  }
  
  .report-temp-container .section-header{
      font-size: 14px;
      font-weight: 600;
      text-align: left;
      color: #4F81BD;
  }

  .section_score_table{
      color: black;
      margin: 5px;
      border-collapse: collapse;
  }

  .section_score_table td, .section_score_table th{
      border: 1px solid #355f91;
      padding:5px;
  }

  .section_score_table td{
      background: #D3DFEE;
  }