.auth-module {
	background-color: $white-color;
	display: flex; 
	justify-content: center;
	flex-direction: column;
	width: 400px;
	margin: 15px;
	box-shadow: 0px 0px 40px 0px rgba(82,63,105,0.1);
	transition: all 0.3s linear;
	-webkit-transition: all 0.3s linear;
	-moz-transition: all 0.3s linear;
	padding: 40px 40px 40px;
	border-radius: .25rem;
	.form-group {
    margin-bottom: 1.5rem;
    position: relative;
    .toggle-show-password {
      position: absolute;
      cursor: pointer;
      @extend .vertical-center;
      right: 1em;
      font-size: 0.875em;
    }
	}
	.module-title {
		margin-bottom: 20px;
		h5 {
			text-align: center;
			color: $heading-color;
			font-size: 16px;
			font-weight: 600;
			letter-spacing: 3px;
			text-transform: uppercase;
			margin-bottom: 0;
		}
		p {
			text-align: center;
			font-size: 14px;
			color: $label-color;
			margin-top: 10px;
			margin-bottom: 0;
		}
	}
	.forgot-pwd {
		a {
			font-size: 14px;
			color: $label-color;
			&:hover {
				color: $primary-color;
			}
		}
  }
	.quick-action {
		margin-top: 30px;
	}
	.btn {
		margin-right: 20px;
		padding: .5rem 2rem;
	}
}

.module-info {
  font-size: 10px;
  width: 100%;
    text-align: center;
}
.min-width{
	width: min-content;
}
.mid-center{
	display: flex;
	align-items: center;
	justify-content: center;
}
