.l-grid-container {
    .module-tile {
        display: flex;
        align-items: center;
        padding: 20px;
        height: 100px;
        width: 300px;
        box-shadow: 0px 0px 40px 0px rgba(82, 63, 105, 0.1);
        background-color: #fff;
        border-radius: 7px;
        margin: 10px;
        cursor: pointer;

        .module-logo {
            object-fit: contain;
            margin-right: 10px;
            border-radius: 0.25rem 0.25rem 0 0;
    
            i {
                line-height: 100px;
                color: #d9dce7;
                font-size: 45px;
            }
        }
    
        .module-content {
            flex: 1;

            .module-title {
                text-transform: uppercase;
                font-size: 15px;
                font-weight: 800;
                font-family: "ArtifaktElement-Regular";
                color: #0996d7;
            }
        
            .module-description {
                margin: 0;
                font-size: 13px;
                color: #646c9a;
            }
        }
    }

    .card {
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 250px;
        margin: 15px;
        box-shadow: 0px 0px 40px 0px rgba(82, 63, 105, 0.1);
        transition: all 0.3s linear;
        -webkit-transition: all 0.3s linear;
        -moz-transition: all 0.3s linear;
        &:hover {
            box-shadow: 0px 0px 60px 0px rgba(82, 63, 105, 0.2);
        }
        &.disabled {
            position: relative;
            opacity: 0.4;
            &:hover {
                box-shadow: 0px 0px 40px 0px rgba(82, 63, 105, 0.1);
            }
            .card-placeholder {
                i {
                    color: tint($placeholder-color, 50);
                }
            }
            .card-title,
            .card-text {
                color: tint($placeholder-color, 50);
            }
            .btn {
                color: tint($placeholder-color, 50);
            }
            &:before {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                border-radius: 0.25rem;
                //background-color: rgba(73,80,87, 0.2);
                background-color: rgba(0, 0, 0, 0.2);
                content: "";
                z-index: 9;
            }
        }
        .card-placeholder {
            height: 100px;
            width: 100%;
            background: #f7f7f7;
            border-radius: 0.25rem 0.25rem 0 0;
            text-align: center;
            i {
                line-height: 100px;
                color: tint($placeholder-color, 60);
                font-size: 50px;
            }
            img {
                position: absolute;
                top: 30%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
        .card-title {
            text-transform: uppercase;
            font-size: 15px;
            font-weight: 800;
            font-family: "ArtifaktElement-Regular";
            color: $primary-color;
        }
        .card-text {
            font-size: 13px;
            color: $label-color;
            margin-bottom: 0;
        }
        .card-body {
            position: relative;
            .btn {
                position: absolute;
                top: -20px;
                right: 20px;
            }
        }
        .btn {
            background-color: $white-color;
            float: right;
            font-size: 16px;
            width: 40px;
            height: 40px;
            line-height: 40px;
            padding: 0;
            color: $primary-color;
            box-shadow: 0px 0px 40px 0px rgba(82, 63, 105, 0.2);
            &:hover {
                color: shade($primary-color, 20);
                box-shadow: 0px 0px 60px 0px rgba(82, 63, 105, 0.3);
            }
        }
    }
}
.components-container {
    height: 100vh;
    background: #f2f3f8;

    .accrucial-selection{
        .l-grid-container{
            .card{
                width: 225px !important;
                cursor: pointer;
            }
        }
    }
}
.container-fluid {
    height: 100%;
    position: relative;
}

.ad-modules{
    overflow: scroll;
    max-height: calc(80vh - 150px);
}
.height-88 {
    display: flex !important;
    flex-wrap: wrap !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
    // height: 75% !important;
}
.number {
    font-family: "Squada One";
    font-size: 4.209em;
    display: inline-block;
    color: $primary-color;
}
.text {
    display: inline-block;
    padding-left: 10px;
    font-family: "Squada One";
    font-size: 1.777em;
    color: $primary-color;
}
.statistics-container {
    display: flex;
    flex-direction: column;
    padding-left: 40px;
    align-items: center;
    justify-content: center;
}
.quote {
    color: $primary-color;
    opacity: 0.1;
    font-size: 13px !important;
    margin-top: 0;
    margin-bottom: 40px;
}
.carousel-container {
    display: flex;
    justify-content: center;
}
.params-container {
    display: flex;
    flex-direction: row;
    gap: 50px;
}
.request-container {
    display: inline-block;
}
.time-saved-container {
    display: inline-block;
}
.br-light {
    // border-right: 1px solid rgba(9, 150, 215, 0.1);
}
.carousel {
    position: absolute !important;
    margin-top: 11em;
    opacity: 0.7;
    width: 100%;
    text-align: center;
}
.text-sm {
    font-size: 0.85em;
}

.vert .carousel-item-next.carousel-item-left,
.vert .carousel-item-prev.carousel-item-right {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.vert .carousel-item-next,
.vert .active.carousel-item-right {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100% 0);
}

.vert .carousel-item-prev,
.vert .active.carousel-item-left {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
}
