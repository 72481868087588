.filter-modal{
    display: inline;
}
.filter-button{
    padding:0px 25px;
}
.add-filter-title{
    font-size: 14px;
    opacity: 0.4;
    font-weight: 400;
}
.form-check input{
    margin-top: 7px;
}
.form-check label{
    font-weight: 400;
    font-size: 13px;
}
.pointer-event{
    pointer-events: none;
}
.mat-form-field-label{
    font-size: 16px;
}
::ng-deep .mat-form-field-appearance-outline .mat-form-field-outline-thick {
    color: rgba(6, 151, 214,0.4);
}
.close-button{
    opacity: 0.4;
}
.displayErrorMessage{
    color: red;
    font-size: 12px;
}
.check-box-error-container{
    display: flex;
    flex-direction: row;
}
.check-box-container{
    flex: 1;
}
.error-container{
    text-align: right;
    flex: 1;
}
.save-desc{
    resize: none !important;
}
.solid-color{
    color: #0996D7;
}
.outline-color{
    color: #646C9A;
}
.fas{
    font-size: medium;
}
.far{
    font-size: medium;
}
.fav-filters{
    text-align: left !important;
    margin-bottom: 4%;
    width: 100%;
    opacity: 0.5;
    font-size: 14px;
}
.my-custom-scrollbar {
    position: relative;
    max-height: 450px;
    overflow: auto;
}
.table-title{
    font-size: 13px;
}
.modal-body{
    tbody {
        tr{
            font-size: 12px;
        }
        td{
            cursor: pointer;
        }
    }
}
.message{
    margin-top: 4%;
    width: 100%;
    text-align: center;
    opacity: 0.4;
    font-size: 14px;
    margin-bottom: 4%;
}
.modal-footer{
    border-top: none !important;
}
.modal-header{
    border-bottom: none !important;
}

.fade-scale {
    -webkit-transform: scale(0.7);
	-moz-transform: scale(0.7);
	-ms-transform: scale(0.7);
	transform: scale(0.7);
	opacity: 0;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
}

.fade-scale.show {
    -webkit-transform: scale(1);
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
	opacity: 1;
}

.slide-in-bottom{
    -webkit-transform: translateY(20%);
	-moz-transform: translateY(20%);
	-ms-transform: translateY(20%);
	transform: translateY(20%);
	opacity: 0;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
}

.slide-in-bottom.show{
    -webkit-transform: translateY(0);
	-moz-transform: translateY(0);
	-ms-transform: translateY(0);
	transform: translateY(0);
	opacity: 1;
}

.slide-in-bottom .modal-dialog{
    position:fixed;
    bottom:0;
    right:0;
    margin:0;
    margin-right: 20px;
    margin-bottom: 20px;
}

