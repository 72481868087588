$pin-cirlce-radius: 12px;

.search-results-info-wrapper {
  display: flex;
  .search-results-indicator {
    margin-bottom: 0.5rem;
    font-size: 12px;
  }
  .search-filter-row {
    display: flex;
    flex-direction: column
  }
  a {
    font-size: 12px;
    margin-left: 10px;
  }
}

.recent-searches-list {
  position: relative;
  margin-top: 6px;
  padding-bottom: 1rem;
  min-height: 400px;
  height: calc(100vh - 230px);
  overflow: hidden;
  .recent-searches-inner {
    max-height: 100%;
    min-height: 400px;
    overflow: auto;
    & > ul {
      list-style-type: none;
      padding-left: 0px;
      position: relative;
      & > li {
          font-size: 12px;
          position: relative;
          display: flex;
          flex-direction: column;
          .name-cirlce-wrapper {
            position: relative;
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            round-progress {
              position: absolute;
              left: 0px;
              top: 0px;
            }
          }
          .name-circle {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            // background-color: #f2f3f8;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .search-data {
            padding-left: 1rem;
            flex: 1;
          }
          .name {
            font-size: 14px;
            margin-bottom: 0px;
            font-weight: 400;
            color: $dark-blue;
          }
          .actions {
            display: grid;
            grid-template-columns: 40px;
            justify-items: center;
            padding-top: 4px;
            color: tint($blue, 20);
            font-size: 1rem;
            div {
              cursor: pointer;
              i {
                width: 40px;
                text-align: center;
              }
            }
          }
          .info-row {
            display: flex;
            // flex-direction: column;
            font-size: 10px;
            div {
              margin-right: 1rem;
              .icon {
                font-size: 8px !important;
                margin-right: 2px;
                .far{
                  font-size: 8px !important;
                }
              }
            }
         
            div:not(:first-child) {

            }
            .created-at {
              display: flex;
              align-items: center;
            }
          }
          .action-links {
            line-height: normal;
            a {
              cursor: pointer;
              margin-right: 6px;
              padding-right: 6px;
              color: tint(#007bff, 30);
              position: relative;
              &:last-child {
                border: none ;
              }
              &:hover {
                color: tint(#007bff, 10);
              }
              &:after {
                content: '';
                width: 1px;
                height: 60%;
                background-color: tint(#007bff, 30);
                @extend .vertical-center;
                right: 0px;
              }
              &:last-child:after {
                content: none;
              }
            }
            & :hover{
              text-decoration: underline;
            }
          }
          .loader-indicator {
            color: tint(#007bff, 30);
          }
          .ows-id {
            display: flex;
            justify-content: center;
            align-items: center;
            .label {
              color: $placeholder-color;
              margin-right: 4px;
            }
          }
          .key {
            color: #646c99;
            padding-right: 8px;
          }
          &:last-child .accordion-wrapper{
            border-bottom: 0px;
          }
        }
      }
  }
.keywords-container {
  ul.keywords-list {
    list-style-type: none;
    padding-left: 0px;
    padding-bottom: 10px;
    li {
      display: inline-block;
      vertical-align: top;
      margin: 2px;
      padding: 1px 4px;
      border: 1px solid tint($label-color, 80);
      border-radius: 2px;
      font-size: 10px;
      line-height: normal;
      color: tint($default-text-color, 20);
    }
  }
}
  .progress-wrapper {
    padding-top: 1rem;
    position: relative;
    ul {
      list-style-type: none;
      padding-left: 0px;
      li {
        position: relative;
        display: flex;
        max-height: 40px;
        .pin-bar {
          position: relative;
          display: flex;
          width: $pin-cirlce-radius;
          min-height: 40px;
          margin-right: 1rem;
          &:before {
            content: '';
            width: $pin-cirlce-radius;
            height: $pin-cirlce-radius;
            background-color: #e0e2eb;
            border-radius: 50%;
            position: relative;
            z-index: 2;   
            box-shadow: 0 0 0 tint($blue, 20);
            @include transform(scale(1));
          }
          &:after {
            content: '';
            width: calc($pin-cirlce-radius / 5);
            height: 100%;
            background: #e0e2eb;
            position: absolute;
            @extend .position-center;
        
          }
          .check-icon, .error-icon {
            position: absolute;
            font-size: calc($pin-cirlce-radius / 2);
            color: $white-color;
            z-index: 99;
            width: $pin-cirlce-radius;
            height: $pin-cirlce-radius;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-left: 1px;
            opacity: 0;
            .fas{
              font-size: calc($pin-cirlce-radius / 2) !important;
            }
          }
          &.searching {
            @extend .pulse;
            &:before {
              background-color: tint($blue, 20);
            }
          }
          &.pending {
            
          }
          &.completed {
            .check-icon {
              opacity: 1;
            }
            &:before {
              background-color: tint($blue, 20);
            }
          }
          &.error {
            &:before {
              background-color: tint($blue, 20);
            }
            .error-icon {
              opacity: 1;
              padding-left: 0px;
            }
            .check-icon {
              opacity: 0;
            }
           & + .content-wrapper p.error-msg { 
              opacity: 1;
            }
          }
        }
        .content-wrapper {
          h1.name {
            margin-top: -2px;
            margin-bottom: 0px;
            font-size: 12px;
          }
          p.error-msg {
            font-size: 10px;
            margin-bottom: 0px;
            color: $invalid-color;
            line-height: normal;
            opacity: 0;
            max-width: 350px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        &:last-child {
          .pin-bar {
            &:after {
              content: none;
            }
          }
        }
      }
    }
    .percentage-indicator {
      position: absolute;
      top: calc(1rem);
      width: 2px;
      background-color: tint($blue, 20);
      left: calc($pin-cirlce-radius / 2) - 1;
      max-height: 400px;
      overflow: hidden;
    }
  }
}
.filter-actions {
  margin-bottom: 8px;
  display: flex;
  width: 100%;
  align-items: center;
  .search-filter {
    display: flex;
    margin-right: 1rem;
    @extend .transition;
    flex: 1;
    justify-content: flex-end;
    .search-wrapper {
      position: relative;
      width: 12.5rem;
      @extend .transition;
      .search-icon {
        position: absolute;
        left: 4px;
        font-size: 12px;
        color: tint($label-color, 30);
        @extend .vertical-center;
      }
      .search-input {
        border: none;
        width: 100%;
        border-bottom: 1px solid $placeholder-color;
        font-size: 12px;
        padding-left: 24px;
        padding-bottom: 3px;
        color: $default-text-color;
        @include placeholder {
          font-size: 12px;
        }
      }
      &.expand-search {
        width: 100%;
      }
    }
  }
  .custom-filter {
    position: relative;
    i {
      cursor: pointer;
    }
    .search-options-list {
      left: unset;
      right: 0px;
    }
  }
  .search-options-list {
    position: absolute;
    top: 100%;
    z-index: 999;
    display: flex;
    flex-direction: column;
    background-color: white;
    margin: 0px;
    box-shadow: 0px 0px 40px 0px rgba(82,63,105,0.1);
    padding: 0px;
    list-style-type: none;
    overflow: hidden;
    left: 0px;
    user-select: none;
    li {
      &.option {
        white-space: nowrap;
        font-size: 12px;
        padding: 8px 10px;
        cursor: pointer;
        border-radius: 2px;
        min-width: 160px;
        background-color: $white-color;
        &:hover {
          background-color: $background-color;
        }
      }
      &.label {
        .label-name {
          font-size: 10px;
          font-weight: 500;
          padding: 0.5rem 0.8rem;
        }
      }
      .sub-list {
        padding-left: 0px;
        .option {
          font-weight: 400;
          padding-left: 18px;
        }
      }
    }
  }
}
.no-reports-container {
  text-align: center;
  font-weight: 200;
  font-size: 14px;
}

.recent-searches-body {
  width: 80%;
  h3.title {
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 4px;
    color: $label-color;
  }
}
