$base_font: 16px;

// @Input: value in pixels (eg: 16px or 16)
// @Action: Converts pixels to 'em' based on $max-font (converting photoshop 'px' to 'rem')
// @Output: value in 'em'
@function rem($pixels, $context: $base_font) {
  
  // To allow user to pass pixels with or without units
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }
  @if (unitless($context)) {
    $context: $context * 1px;
  }
  @return #{$pixels/$context}rem;
}


html {
  font-size: $base_font;
}

body {
  font-size: 16px;
  font-weight: 400;
}