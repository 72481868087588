@import url("https://maxcdn.bootstrapcdn.com/bootstrap/latest/css/bootstrap.min.css");

$bluelight: (
    50: #e1f2fa,
    100: #b5e0f3,
    200: #84cbeb,
    300: #53b6e3,
    400: #2ea6dd,
    500: #0996d7,
    600: #088ed3,
    700: #0683cd,
    800: #0579c7,
    900: #0268be,
    A100: #e6f2ff,
    A200: #b3d8ff,
    A400: #80beff,
    A700: #67b1ff,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #000000,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000,
    ),
);

$chips: (
    50: #ffffff,
    100: #ffffff,
    200: #ffffff,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #ffffff,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
    contrast: (
        50: rgba(0, 0, 0, 0.4),
        100: rgba(0, 0, 0, 0.4),
        200: rgba(0, 0, 0, 0.4),
        300: rgba(0, 0, 0, 0.4),
        400: rgba(0, 0, 0, 0.4),
        500: rgba(0, 0, 0, 0.4),
        600: rgba(0, 0, 0, 0.4),
        700: rgba(0, 0, 0, 0.4),
        800: rgba(0, 0, 0, 0.4),
        900: rgba(0, 0, 0, 0.4),
        A100: rgba(0, 0, 0, 0.4),
        A200: rgba(0, 0, 0, 0.4),
        A400: rgba(0, 0, 0, 0.4),
        A700: rgba(0, 0, 0, 0.4),
    ),
);

.popupBackdropClass {
    opacity: 0;
}

html,
body {
    height: 100%;
}
body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    background-color: #f2f3f8 !important;
}

.application-loading-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.application-loading-box {
    width: 300px;
    margin: 5px;
    text-align: center;
}

.spinner-border {
    width: 5rem !important;
    height: 5rem !important;
    color: #0996d7 !important;
}

input::-webkit-input-placeholder {
    font-size: 12px !important;
}

::-moz-placeholder {
    font-size: 12px !important;
}

:-ms-input-placeholder {
    /** notice that ie has only a single colon) */
    font-size: 12px !important;
}

::-webkit-input-placeholder {
    font-size: 12px !important;
}

::placeholder {
    font-size: 12px !important;
}
