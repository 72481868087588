.watchdog-container {
  padding: 15px;
  background-color: $white-color;
  margin: 1rem;
  height: calc(100vh - 96px);
  min-height: 500px;
  // width: 80vw;
  border: 1px solid tint($placeholder-color, 60);
  // box-shadow: 0 0 40px 0 rgba(82, 63, 105, 0.1);
  overflow: hidden;
  .tabs-container {
    display: flex;
    margin-bottom: 0.8rem;
    border-bottom: 1px solid $background-color;
    .tab {
      padding: 6px 24px;
      padding-top: 0px;
      font-weight: 300;
      cursor: pointer;
      // opacity: 0;
      position: relative;
      font-size: 1rem;
      &.active{
        border-bottom: 2px solid #2b94d5;
        font-weight: 400;
      }
      &:hover {
        font-weight: 400;
        // border-bottom: 2px solid #2b94d5;
      }
      // &:before {
      //   content: attr(data-content);
      //   @extend .position-center
      // }
    }
  }
  .search-container {
    display: flex;
    justify-content: space-between;
  }
  .new-search-container,
  .recent-searches-container {
    padding: 10px;
    display: flex;
    flex-direction: column;
    min-height: 530px;
    flex: 1;
    h1.title {
      font-size: 18px;
      color: $secondary-color;
      font-weight: 300;
      position: relative;
      // text-transform: uppercase;
      letter-spacing: 0.7px;
      margin-bottom: 0.5rem;
      .icon {
        margin-right: 8px;
        font-size: 14px;
      }
      &:before {
        content: '';
        position: absolute;
        bottom: 0px;
        width: 100%;
        height: 1px;
      }
    }
  }
  .new-search-container {
    form {
      all: unset;
    }
    width: 45%;
    margin-right: 5%;
  }
  .new-search-wrapper{
    min-height: 400px;
    height: calc(100vh - 230px);
    overflow: hidden;
  }
  .new-search-inner-wrapper {
    overflow: auto;
    min-height: 400px;
    max-height: 100%;
  }
  .search-inputs-wrapper {
    overflow: auto;
    margin-bottom: 8px;
  }
  ul.search-options {
    list-style-type: none;
    padding-left: 0px;
    display: flex;
    margin-top: 0.5rem;
    li {
      padding: 2px 10px;
      font-size: 12px;
      margin-right: 8px;
      // border-radius: 14px;
      cursor: pointer;
      user-select: none;
    }
    &.filters {
      li {
        border: 1px solid tint(#336294, 20);
      }
    }
    &.status-filters {
      .completed {
        color: $green-color;
        border: 1px solid $green-color;
        &.active {
          background-color: $green-color;
          color: $white-color;
        }
      }
      .pending {
        border: 1px solid #646c9a;
        &.active {
          background-color: #646c9a;
          color: $white-color;
        }
      }
      .error {
        color: $invalid-color;
        border: 1px solid $invalid-color;
        &.active {
          background-color: $invalid-color;
          color: $white-color;
        }
      }
    }
  }



// todo verify and clean
  .search-options-filters {
    display: flex;
    margin: 0.5rem 0 1rem;
    label {
      all: unset;
      padding: 2px 10px;
      font-size: 12px;
      // border-radius: 14px;
      cursor: pointer;
      user-select: none;
      border: 1px solid tint(#336294, 20);
      width: 80px;
      box-sizing: border-box;
      &:first-child {
        border-right-width: 0px;
      }
      &:last-child {
        border-left-width: 0px;
      }
    }
  }
  .ows-loading {
    right: 20px;
    @extend .vertical-center;
    display: block;
    color: $secondary-color;
  }
  .filter-active {
    background-color: #336294;
    color: $white-color;
  }
  
}

input[id="ows-data"]:checked ~ label[for="ows-data"], input[id="keywords"]:checked ~ label[for="keywords"] {
  background-color: #336294;
  color: $white-color;
}

 
