.pagination-container {
  position: fixed;
  width: 70%;
  bottom: 14px;
  .d-flex {
    margin-top: 0px;
    p {
      font-size: 12px;
    }

    .paginate-result {
      padding: 8px 30px 8px 0;
      margin-top: 13px;
      margin-bottom: 13px;
      position: relative;
    }
  }

  .pagination-loader {
    position: relative;
    font-size: 12px;
    display: inline-block;
    padding: 6px 15px 6px 0;
    &:before {
      position: absolute;
      content: '';
      left: -16px;
      top: 6px;
      height: 20px;
      border-right: 1px solid tint($placeholder-color, 70);
    }
    i {
      font-size: 20px;
      color: tint($label-color, 50);
    }
    .l-text {
      padding-left: 8px;
      vertical-align: text-bottom;
    }
  }
}

.pagination {
  margin-top: 13px;

  .page-item {
    &.disabled {
      a {
        color: $placeholder-color;
      }

    }

    &.active {
      a {
        color: $white-color;
        background: $primary-color;
        border-color: $primary-color;
      }
    }

    a {
      font-size: 14px;
      color: $label-color;

      &.page-link {
        &:focus {
          box-shadow: none;
        }
      }
    }
  }
}